import React from "react";
import { Link, StaticQuery } from "gatsby";
import github from "../img/github-icon.svg";
import logo from "../img/logo.svg";
import { AST_StatementWithBody } from "terser";
import { Location } from "@reach/router";

function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: this.props.location.pathname === "/",
      expandedClassName: "",

      isOpen: false,
      isScrolled: false
    };
  }

  componentDidMount = props => {
    const handleScroll = isScrolled => {
      if (isScrolled !== this.state.isScrolled) {
        this.setState({ isScrolled });
      }
    };
    //throttle(() => , 250)
    const debounced = () =>
      debounce(
        handleScroll(
          (document.documentElement.scrollTop || document.body.scrollTop) > 250
        ),
        1000
      );
    document.body.onscroll = debounced;
    debounced();

    const navItems = this.props.items
      .filter(d => d.fields.slug !== "/")
      .map(({ fields, frontmatter }) => {
        return {
          title: frontmatter.title,
          url: fields.slug
        };
      });

    // This is extremely hacky -> so that we can use these properties in
    // a custom netlify-cms widget. I can't find a way to use gatsby graphql
    // queries here so I'm accessing them from localstorage in the netlify cms page
    window.localStorage.setItem("pageInfo", JSON.stringify(navItems));
  };

  toggleMenu = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const s = this.state;
    const navItems = this.props.items
      .filter(d => d.fields.slug !== "/")
      .map(({ fields, frontmatter }) => {
        return {
          title: frontmatter.title,
          url: fields.slug,
          order: frontmatter.order
        };
      });

    const Links = navItems
      .sort((a, b) => b.order - a.order)
      .map((i, k) => (
        <Link
          to={i.url}
          onClick={() => this.setState({ isOpen: false })}
          key={k}
        >
          {i.title}
        </Link>
      ));

    if (typeof document !== `undefined`) {
      if (!this.state.isOpen) {
        document.documentElement.style.overflow = "scroll";
      } else {
        document.documentElement.style.overflow = "hidden";
      }
    }
    return (
      <div>
        <div
          className={`
            ${s.isScrolled || !s.active ? "compact-nav" : ""}
            ${s.isOpen ? "menu-open" : ""}
            nav`}
        >
          <div className="menu-bar">
            <Link to="/">
              <div className="logo">
                Gamot Cogon
                <br /> Waldorf School
              </div>
            </Link>
            <div onClick={this.toggleMenu} className="menu pointer">
              <div
                id=""
                className={"nav-icon3 " + (this.state.isOpen ? "open" : "")}
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>

          <div
            className={`${
              this.state.isOpen ? "menu-content-open" : "menu-content-close"
            } menu-content`}
          >
            {Links}
            {/* <Link to='/what-is-waldorf'>What is Waldorf?</Link> <br/>
              <Link to='/ethos'>Our Ethos</Link> <br/>
              <Link to='/lower-school'>Lower School</Link> <br/>
              
              <Link to='/middle-school'>Middle School</Link> <br/>
              <Link to='/high-school'>High School</Link> <br/> */}
          </div>
        </div>
      </div>
    );
  }
};

export default () => {
  return (
    <Location>
      {({ location }) => {
        return (
          <StaticQuery
            query={graphql`
              query {
                allMarkdownRemark {
                  nodes {
                    fields {
                      slug
                    }
                    frontmatter {
                      order
                      title
                    }
                  }
                }
              }
            `}
            render={data => (
              <Navbar
                location={location}
                items={data.allMarkdownRemark.nodes}
              />
            )}
          />
        );
      }}
    </Location>
  );
};
